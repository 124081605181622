import React from 'react';

function PoweredBy() {
  return (
    <div className='powered-by'>
      &copy; Copyright 2020 - Luis Aldrey
    </div>
  );
}

export default PoweredBy;
